import React from 'react';
import { IconButton } from 'react-style-guide';
import layoutConstant from '../constants/layoutConstants';

const { headerMenuIconClickEvent } = layoutConstant;

function MenuIcon() {
  const { iconTypes } = IconButton;
  const onClickMenuIcon = () => {
    document.dispatchEvent(new CustomEvent(headerMenuIconClickEvent.name));
  };

  return (
    <IconButton
      id='header-menu-icon'
      iconType={iconTypes.navigation}
      iconName='nav-menu'
      onClick={onClickMenuIcon}
    />
  );
}

MenuIcon.propTypes = {};

export default MenuIcon;
